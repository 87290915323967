<div class="navbar-container">
    <div class="navbar">
        <div class="logo" routerLink="/">
            <img src="assets/images/logo.svg" />
        </div>
    </div>
    <div class="consent container">
        <h2 class="consent__header">
            Політика конфіденційності користування мобільного додатку «DATACHECK UKRAINE»
        </h2>
        <h3 class="consent__header">Загальні положення</h3>
        <p class="consent__text">
            Ця Політика конфіденційності містить загальну інформацію про забезпечення Благодійною
            організацією «Всеукраїнська мережа людей, які живуть з ВІЛ/СНІД» (далі – Благодійна
            організація) конфіденційності інформації у мобільному додатку «DATACHECK UKRAINE» (далі
            – мобільний додаток). Це важлива інформація, і Благодійна організація сподівається, що
            користувачі уважно ознайомляться з нею. Благодійна організація знає наскільки важливою є
            конфіденційність (таємність) інформації та безпека для користувачів мобільного додатку.
        </p>
        <p class="consent__text">
            Персональні дані у розумінні цієї Політики конфіденційності – це інформація, яка дає
            можливість ідентифікувати користувачів, а також інформація про діяльність користувачів,
            наприклад, про використання певних сервісів, тощо, якщо таке використання прямо
            пов’язане з інформацією, що дозволяє ідентифікувати користувачів.
        </p>
        <p class="consent__text">
            Використовуючи мобільний додаток, користувачі погоджуються та приймають її вимоги/норми,
            надають дозвіл на адаптування, зміну, поновлення, використання і поширення,
            знеособлення, знищення конфіденційної інформації, зокрема, персональних даних, у тому
            числі з використанням інформаційних систем у порядку, визначеному цією Політикою
            конфіденційності. Обробка персональних даних користувачів починається з моменту
            користування мобільним додатком.
        </p>
        <p class="consent__text">
            Благодійна організація зберігає за собою право у будь-який час змінювати цю Політику
            конфіденційності без персонального повідомлення кожного користувача. Натомість,
            Благодійна організація зазначає час останніх змін, що були внесені до Політики
            конфіденційності в колонтитулі документу. Продовжуючи використовувати мобільний додаток
            після внесення таких змін, користувачі погоджуються та приймають їх як частину Політики
            конфіденційності.
        </p>
        <br />
        <h3 class="consent__header">
            Перелік конфіденційної інформації, що обробляється Благодійною організацією для надання
            користувачам мобільного додатку
        </h3>
        <p class="consent__text">
            Благодійна організація збирає та обробляє інформацію, що визначена у наданій Благодійною
            організацією згоді на обробку конфіденційної інформації, зокрема, персональних даних, у
            Політиці конфіденційності, у тому числі:
        </p>
        <ul class="consent__secondary-list">
            <li class="consent__item">прізвище, ім’я, по батькові (ПІБ);</li>
            <li class="consent__item">
                дата народження, стать, номер телефону, електронна пошта, місце проживання та/або
                місце реєстрації, документи, що посвідчують користувача та підтверджують
                громадянство або підтверджують спеціальний статус користувача, а також інші дані,
                необхідність обробки яких визначена специфікою діяльності Благодійної організації, в
                тому числі, але не виключно, медичні дані та дані, які стосуються стану здоров’я (в
                тому числі, але не виключно, відомості про ВІЛ-статус, клінічний діагноз, наявність
                встановленої групи інвалідності, отримання медичних послуг, облік у медичних
                закладах, результати медичного обстеження, анамнез захворювання тощо), які необхідні
                для виконання Благодійною організацією статутних та програмних завдань;
            </li>
            <li class="consent__item">
                інформація про пристрій – таку як: назва та версія операційної системи, апаратна
                модель, IMEI, IMSI та інші унікальні ідентифікатори пристрою. Благодійна організація
                не збирає інформацію про історію викликів, список контактів, СМС (SMS-повідомлень)
                та інше;
            </li>
            <li class="consent__item">
                інформація про дії користувача у мобільному додатку – таку як: час та тривалість
                користування мобільним додатком, дії, які користувач здійснював під час використання
                мобільного додатку, IP адресу, геолокацію під час користування мобільним додатком;
            </li>
            <li class="consent__item">
                файли Сookie та інші технології збору даних такі як фінгерпринт, тощо.
            </li>
        </ul>
        <p class="consent__text">
            Метою використання Благодійною організацією конфіденційної інформації, зокрема,
            персональних даних користувачів є використання мобільного додатку, надання, підтримка,
            захист та покращення наявних в мобільному додатку та розробка нових функції мобільного
            додатку.
        </p>
        <br />
        <h3 class="consent__header">
            Поширення та розкриття конфіденційної інформації, зокрема, персональних даних
        </h3>
        <p class="consent__text">
            Благодійна організація дотримується вимог чинного законодавства України та не здійснює
            передачу конфіденційної інформації, зокрема, персональних даних третім особам, крім
            випадків, передбачених чинним законодавством України, згодою на обробку персональних
            даних та цією Політикою конфіденційності.
        </p>
        <br />
        <h3 class="consent__header">Захист конфіденційної інформації</h3>
        <p class="consent__text">
            Конфіденційна інформація, зокрема, персональні дані, що належить користувачам,
            зберігається на серверах Благодійної організації, який діє згідно вимог чинного
            законодавства України. Передача та рух інформаційних потоків повністю захищений.
        </p>
        <p class="consent__text">
            У разі отримання доступу третьої особи до облікового запису користувача, Благодійна
            організація не несе відповідальності за негативні наслідки, які настали або могли б
            настати в результаті втрати доступу до власного облікового запису користувачем.
        </p>
        <p class="consent__text">
            Користувач самостійно забезпечує збереження інформації про свої паролі та іншої
            конфіденційної інформації, зокрема, персональних даних. Центр не несе відповідальності
            за наслідки втрати користувачем конфіденційної інформації, зокрема, персональних даних.
            Дане положення поширюється як на поширення користувачем конфіденційної інформації,
            зокрема, персональних даних, третім особам добровільно, так і на втрату користувачем
            конфіденційної інформації, зокрема, персональних даних, поза його волею.
        </p>
        <p class="consent__text">
            В разі наявної в користувача інформації, що його конфіденційна інформація, зокрема,
            персональні дані у мобільному додатку були скомпрометовані, він зобов’язується
            повідомити про це Розпорядника.
        </p>
        <br />
        <h3 class="consent__header">Строк зберігання конфіденційної інформації</h3>
        <p class="consent__text">
            Конфіденційна інформація, зокрема, персональні дані, користувача зберігається протягом
            строку, який є необхідним задля досягнення мети обробки конфіденційної інформації,
            зокрема, персональних даних, користувача, але не може бути меншим як 10 (десять) років.
            Конфіденційна інформація, зокрема, персональні дані, можуть бути знищена після
            закінчення строку зберігання або у випадках, передбачених чинним законодавством України.
        </p>
        <br />
        <h3 class="consent__header">Зворотній зв’язок</h3>
        <p class="consent__text">
            У разі виникнення питань, побажань, скарг, інформації̈, яку ви надаєте, звертайтесь за
            електронною адресою:
            <a href="mailto:datacheck@network.org.ua">datacheck@network.org.ua</a>
        </p>
        <br />
        <h3 class="consent__header">Заключні положення</h3>
        <p class="consent__text">
            Вимоги Політики конфіденційності розповсюджуються на користувача з моменту, коли під час
            використання мобільного додатка користувач зареєструвався та погодився з умовами цієї̈
            Політики конфіденційності, та діє до тих пір, поки будь-яка конфіденційна інформація,
            зокрема, персональні дані про користувача, зберігається у Благодійної організації.
        </p>
    </div>
</div>
